// Map updated news date values
import i18n from 'i18next';

import moment from 'moment-timezone';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ca';

import { getLocaleDateFormat } from './LocaleUtils';

const locale = (() => {
  const i18nSelected = i18n.language;

  switch (i18nSelected) {
    case 'es':
      return 'es';
    case 'ca':
      return 'ca';
    case 'en':
    default:
      return 'en';
  }
})();

export const dateParse = (date) => {
  if (!date) return null;

  // Method getMonth returns a value from 0 to 11
  const month = date.getMonth() + 1;

  // Concatenate a string to match the month index for locale translation
  const i18nDateMonthIndex = `month.${(`0${month}`).substr(-2)}`;

  return {
    day: date.getDate(),
    month,
    monthIndex: i18nDateMonthIndex,
    year: date.getFullYear(),
  };
};

export const getDate = (date) => (
  date && moment(date).utc().add(2, 'hours').locale(locale)
);

export const getYears = (date) => (
  date && date.isUTC && moment().diff(date, 'years')
);

export const getLocaleYears = (date) => (
  date && date.isUTC && moment().locale(locale).diff(date, 'years')
);

export const getDateYears = (date) => (
  date && getYears(moment(date).utc().locale(locale))
);

export const getFormattedDate = ({ date, weekday, format }) => {
  try {
    const dateTime = date.length <= 10 ? `${date} 12:00:00` : date;
    const label = format || 'label';
    const dateFormat = getLocaleDateFormat(weekday)[label];
    return date && dateFormat && moment(dateTime)
      .utc()
      .add(2, 'hours')
      .locale(locale)
      .format(dateFormat);
  } catch (e) {
    return null;
  }
};

export const getFormattedTime = ({ date, utc }) => {
  try {
    const time = moment(date).utc().add(2, 'hours').locale(locale);
    return (utc ? time : time.local()).format('HH:mm');
  } catch (e) {
    return null;
  }
};

export const getFormattedDateTime = ({ date, weekday, utc }) => {
  try {
    const dateTime = date.length <= 10 ? `${date} 12:00:00` : date;
    const time = moment(dateTime).utc().add(2, 'hours').locale(locale);
    return (utc ? time : time.local()).format(`${getLocaleDateFormat(weekday).label} [·] HH:mm`);
  } catch (e) {
    return null;
  }
};

export const getDateTimeOption = ({ date, weekday }) => {
  try {
    const dateTime = date.length <= 10 ? `${date} 12:00:00` : date;
    const time = moment(dateTime).utc().add(2, 'hours').locale(locale).local();
    const now = new Date();

    const today = `${now.getFullYear()}-${(`0${now.getMonth() + 1}`).substring(-2)}-${now.getDate()}`;
    const day = time.format('YYYY-MM-DD');

    return (day === today ? time.format('HH:mm') : time.format(getLocaleDateFormat(weekday).label));
  } catch (e) {
    return null;
  }
};

export const getFromNowTime = ({ date }) => {
  const momentDate = date && moment(date).utc().add(2, 'hours');
  return momentDate && momentDate.locale(locale).fromNow();
};

export const getDateTimeFromNowTimeObject = (props) => (
  {
    dateTime: getFormattedDateTime(props),
    fromNow: getFromNowTime(props.date),
  }
);

export const getDateFromDateTimeText = (dateText) => dateText.split(' ')[0];

export const getDateTextFromDateInstance = (date) => (
  date instanceof Date && date.toString() !== 'Invalid Date' && new Date(date.getTime()).toISOString().split('T')[0]
);
